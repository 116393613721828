<template >
  <div class="fixed-btn">
    <a href="#up">
      <div class="flecha-btn"></div>
    </a>
  </div>
</template>
<script>
export default {};
</script>
<style>
.fixed-btn {
  position: fixed;
  right: 13vw;
  bottom: 13vw;
  z-index: 1000;
}
.flecha-btn {
  width: 27.570093457943923vw;
  height: 27.570093457943923vw;
  background-image: url('../assets/footer/CIRCLEUP_MV.png');
  background-size: 100%;
  background-repeat: no-repeat;
    filter: drop-shadow(0.156vw 0.156vw 0.104vw rgba(0, 0, 0, 0.521));
}
@media (min-width: 768px) {
    .fixed-btn {
    right: 8vw;
    bottom: 8vw;
}
    .flecha-btn{
        width: 6.145833333333333VW;
        height: 6.145833333333333VW;
    }
}
/**** src="../assets/footer/CIRCLEUP_MV.png" */
</style>